import { render, staticRenderFns } from "./ConsolidationCreate.vue?vue&type=template&id=f570c6c8&scoped=true"
import script from "./ConsolidationCreate.vue?vue&type=script&lang=js"
export * from "./ConsolidationCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f570c6c8",
  null
  
)

export default component.exports